import { render, staticRenderFns } from "./screenInfographic.vue?vue&type=template&id=683e1985&scoped=true&"
import script from "./screenInfographic.vue?vue&type=script&lang=js&"
export * from "./screenInfographic.vue?vue&type=script&lang=js&"
import style0 from "./screenInfographic.vue?vue&type=style&index=0&id=683e1985&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683e1985",
  null
  
)

export default component.exports